// Generated by Framer (1c1e241)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);

const enabledGestures = {"I104:382;58:378": {hover: true}};

const cycleOrder = ["I104:382;58:378"];

const serializationHash = "framer-vkFGw"

const variantClassNames = {"I104:382;58:378": "framer-v-1sphvs4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, d8ABvOg7g: title ?? props.d8ABvOg7g ?? "View Project"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, d8ABvOg7g, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I104:382;58:378", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-vkFGw", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1sphvs4", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"I104:382;58:378"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"I104:382;58:378-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U2F0b3NoaS1ib2xk", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "700", "--framer-letter-spacing": "0px", "--framer-line-height": "150%", "--framer-text-color": "var(--extracted-r6o4lv, rgba(17, 16, 17, 0.8))"}}>View Project</motion.p></React.Fragment>} className={"framer-1nvwhuy"} data-framer-name={"Almost before we kne"} fonts={["FS;Satoshi-bold"]} layoutDependency={layoutDependency} layoutId={"I104:382;58:379"} style={{"--extracted-r6o4lv": "rgba(17, 16, 17, 0.8)", "--framer-paragraph-spacing": "0px"}} text={d8ABvOg7g} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-oqsfjh-container"} layoutDependency={layoutDependency} layoutId={"OcvJD9MH7-container"}><Material color={"var(--token-77abf34c-bfd0-46e4-bdc5-396472ca5ca6, rgba(17, 16, 17, 0.6)) /* {\"name\":\"Text Subtle\"} */"} height={"100%"} iconSearch={"Home"} iconSelection={"ArrowForward"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"OcvJD9MH7"} layoutId={"OcvJD9MH7"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-vkFGw [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vkFGw .framer-865ede { display: block; }", ".framer-vkFGw .framer-1sphvs4 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-vkFGw .framer-1nvwhuy { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-vkFGw .framer-oqsfjh-container { flex: none; height: 24px; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vkFGw .framer-1sphvs4 { gap: 0px; } .framer-vkFGw .framer-1sphvs4 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-vkFGw .framer-1sphvs4 > :first-child { margin-left: 0px; } .framer-vkFGw .framer-1sphvs4 > :last-child { margin-right: 0px; } }", ".framer-vkFGw.framer-v-1sphvs4.hover .framer-1sphvs4 { gap: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vkFGw.framer-v-1sphvs4.hover .framer-1sphvs4 { gap: 0px; } .framer-vkFGw.framer-v-1sphvs4.hover .framer-1sphvs4 > * { margin: 0px; margin-left: calc(16px / 2); margin-right: calc(16px / 2); } .framer-vkFGw.framer-v-1sphvs4.hover .framer-1sphvs4 > :first-child { margin-left: 0px; } .framer-vkFGw.framer-v-1sphvs4.hover .framer-1sphvs4 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 139
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"GvJ8F9MRc":{"layout":["auto","auto"]}}}
 * @framerVariables {"d8ABvOg7g":"title"}
 * @framerImmutableVariables true
 */
const FramerxaeyqApXS: React.ComponentType<Props> = withCSS(Component, css, "framer-vkFGw") as typeof Component;
export default FramerxaeyqApXS;

FramerxaeyqApXS.displayName = "Link Button";

FramerxaeyqApXS.defaultProps = {height: 27, width: 139};

addPropertyControls(FramerxaeyqApXS, {d8ABvOg7g: {defaultValue: "View Project", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerxaeyqApXS, [{family: "Satoshi", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/LAFFD4SDUCDVQEXFPDC7C53EQ4ZELWQI/PXCT3G6LO6ICM5I3NTYENYPWJAECAWDD/GHM6WVH6MILNYOOCXHXB5GTSGNTMGXZR.woff2", weight: "700"}, ...MaterialFonts])